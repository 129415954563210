import { SPage } from "servisofts-component";
import Model from "../../Model";
import list from "./list";
import table from "./table";
import _new from "./new";
import profile from "./profile";
import edit from "./edit";
import _delete from "./delete";
import eliminados from "./eliminados";
import restore from "./restore";
import multiplicador_billetera from "./multiplicador_billetera";
import balance_usuarios from "./balance_usuarios";

const model = Model.billetera;
export const Parent = {
    name: "billetera",
    path: `/billetera`,
    model
}
export default SPage.combinePages(Parent.name, {
    "": list,
    "list": list,
    "table": table,
    "new": _new,
    "profile": profile,
    "edit": edit,
    "delete": _delete,
    "eliminados": eliminados,
    "restore": restore,
    "balance_usuarios": balance_usuarios,
    ...multiplicador_billetera, 
})
