import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SPage, SPopup, STable2, SNavigation, SMath } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'
class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {};
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })

        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/medallas_asfi.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin]
        }).then(e => {
            SSocket.sendPromise({
                component: "insignia",
                type: "getAll",
            }).then(data => {
                const insigniasMap = Object.values(data.data).reduce((map, ins) => {
                    map[ins.key] = ins.nombre; 
                    return map;
                }, {});

                Object.values(e.data).reduce((result, e) => {
                    const medallasPorNombre = {};

                    if (e.medallas) {
                        Object.values(e.medallas).forEach(med => {
                            let nombreInsignia = insigniasMap[med.key_insignia];
                            if (nombreInsignia) {
                                nombreInsignia = nombreInsignia
                                    .toLowerCase()
                                    .replace(/\s+/g, '_')
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '');

                                medallasPorNombre[nombreInsignia] = { ...med }; 
                            }
                        });
                    }

                    e.medallas = medallasPorNombre 
                }, {});


            }).catch(e => {
                SPopup.alert(e.error)
                this.setState({ loading: false, error: e.error })
            })

            let keys = [...new Set(Object.values(e.data).map(a => a.key_usuario).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(e.data).map(a => {
                    a.usuario = e2?.data[a.key_usuario]?.usuario ?? {}
                })
                this.setState({ data: e.data })
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    insigniaHandler({ val, insignia, type, cupon }) {
        let ins = val[insignia];
        switch (type) {
            case 'cantidad':
                return ins ? ins.cantidad : '---';
            case 'beneficio':
                if (ins) {
                    return ins.total_delivery > 0 ? `${ins.total_delivery} Bs` : 'No se benefició en su delivery';
                } else {
                    return '---';
                }
                case 'cupon':
                    if (ins) {
                        return ins.total_cupon > 0 ? `${ins.total_cupon} Bs` : 'No se benefició en su cupón';
                    } else {
                        return '---';
                    }
            default:
                return 'No se ha especificado el tipo de insignia';
        }
    }

    renderTable() {
        if (!this.state.data) return <SLoad />;
        return (
            <STable2
                header={[
                    { key: "usuario", label: "NOMBRE CLIENTE", width: 200, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
                    { key: "cantidad_pedidos_con_tapeke", label: "Cantidad de Pedidos con Tapeke ", center: true, width: 180 },

                    { key: "medallas-comprometido-cantidad", label: "Insignia desbloqueada “Comprometido” 20% (cantidad de veces desbloqueada)", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'comprometido', type: 'cantidad' }) },
                    { key: "medallas-comprometido-beneficios", label: "Beneficio en pedidos Comprometido (benefició en su delivery) en Bs", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'comprometido', type: 'beneficio' }) },

                    { key: "medallas-amigo-verde-cantidad", label: "Insignia desbloqueada “Amigo Verde” 30% (cantidad de veces desbloqueada)", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'amigo_verde', type: 'cantidad' }) },
                    { key: "medallas-amigo-verde-beneficio", label: "Beneficio en pedidos Amigo Verde (benefició en su delivery) en Bs", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'amigo_verde', type: 'beneficio' }) },

                    { key: "medallas-guardian-sostenible-cantidad", label: "Insignia desbloqueada Guardian Sostenible 40% (cantidad de veces desbloqueada)", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'guardian_sostenible', type: 'cantidad' }) },
                    { key: "medallas-guardian-sostenible-beneficio", label: "Beneficio en pedidos Guardían sostenible (solo debe reflejar si se benefició en su delivery) en Bs", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'guardian_sostenible', type: 'beneficio' }) },
                    { key: "medallas-guardian-sostenible-cupon", label: "Cupón Desbloqueado Guardían sostenible 20Bs (conteo si utilizó el cupón) en Bs", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'guardian_sostenible', type: 'cupon' }) },

                    { key: "medallas-heroe_tapeke-cantidad", label: "Insignia desbloqueada Héroe Tapeke (cantidad de veces desbloqueada)", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'heroe_tapeke', type: 'cantidad' }) },
                    { key: "medallas-heroe_tapekebeneficio", label: "Beneficio en pedidos Héroe Tapeke (solo debe reflejar si se benefició en su delivery) en Bs", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'heroe_tapeke', type: 'beneficio' }) },
                    { key: "medallas-heroe_tapeke-cupon", label: "Cupón Desbloqueado Héroe Tapeke 25 Bs (conteo si utilizó el cupón) en Bs", center: true, width: 400, render: val => this.insigniaHandler({ val: val, insignia: 'heroe_tapeke', type: 'cupon' }) },

                    { key: "logros", label: "Logros", center: true, width: 100 },
                    { key: "total_logros", label: "Monto Logros", center: true, width: 100, render: a => !a ? 0 : SMath.formatMoney(a ?? 0) },
                ]}
                data={this.state.data}
            />
        );
    }

    render() {
        return <SPage
            title={`Reporte de PROMO HÉROE TAPEKE ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);