import { SPage } from "servisofts-component";
import root from "./root";
import encryptar_tarjetas from "./encryptar_tarjetas"
import tabla_historial from "./tabla_historial";

export default SPage.combinePages("devtool", {
    "": root,
    encryptar_tarjetas,
    tabla_historial


})
