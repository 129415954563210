import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
    info: {
        component: "descuento"
    },
    Columns: {
        "key": { type: "text", pk: true },
        "key_usuario": { type: "text", fk: "usuario" },
        "fecha_on": { type: "timestamp", label: "Fecha de creación" },
        "estado": { type: "integer" },
        "fecha_inicio": { type: "timestamp", editable: true, notNull: true, label: "Fecha de inicio" },
        "fecha_fin": { type: "timestamp", editable: true, notNull: true, label: "Fecha de fin" },
        "porcentaje": { type: "double", editable: true, notNull: false, label: "Porcentaje" },
        "monto": { type: "double", editable: true, notNull: true, label: "Monto" },
        "delivery": { type: "double", editable: true, notNull: true, label: "Porcentaje para el delivery" },
        "producto": { type: "double", editable: true, notNull: true, label: "Porcentaje para el Producto" },
        "descripcion": { type: "text", editable: true, notNull: true, label: "Descripción" },
        "isglobal": { type: "boolean", editable: true, label: "Es Global" },
        "solo_primera_compra": { type: "boolean", editable: true, label: "Solo primera compra" },
        "cobertura": { type: "double", editable: true, notNull: true, label: "Porcentaje que asume Tapeke" },
        "tipo_rango": { type: "text", label: "Para saber si se aplica a 'delivery' o a 'producto'" },
        "key_recompensa_insignia": { type: "text", label: "key_recompensa_insignia", fk: "recompensa_insignia", editable: true },
    },
    Action,
    Reducer,
});