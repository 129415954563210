import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup, Upload } from 'servisofts-component';
import SSocket from 'servisofts-socket'
import Model from '../../../Model';
import PButtom from '../../../Components/PButtom';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            params: ["key_restaurante"],
            excludes: []
        });
        this.state = {
            loading: false,
        };
    }
    $allowAccess() {
        var data = Parent.model.Action.getByKey(this.pk);
        if (!data) return null;

        let cp = Model.categoria_producto.Action.getByKey(data.key_categoria_producto);

        if (!!cp && !this.state.categoria_producto) {
            this.setState({ categoria_producto: cp })
        }

        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    $inputs() {
        var inp = super.$inputs();
        inp["precio"].type = "money"
        inp["precio"].defaultValue = parseFloat(inp["precio"].defaultValue ?? 0).toFixed(2);

        inp["mayor_edad"].type = "select"
        inp["mayor_edad"].options = [{ key: "", content: "SELECCIONAR" }, { key: "true", content: "SI" }, { key: "false", content: "NO" }]
        inp["mayor_edad"].defaultValue = inp["mayor_edad"].defaultValue + ""

        inp["ley_seca"].type = "select"
        inp["ley_seca"].options = [{ key: "", content: "SELECCIONAR" }, { key: "true", content: "SI" }, { key: "false", content: "NO" }]
        inp["ley_seca"].defaultValue = inp["ley_seca"].defaultValue + ""

        inp["key_categoria_producto"].value = this.state?.categoria_producto?.nombre;

        inp["key_categoria_producto"].onPress = (val) => {
            SNavigation.navigate("/restaurante/categoria_producto/list", {
                key_restaurante: this.$params.key_restaurante, onSelect: (val) => {
                    this.setState({ categoria_producto: val })
                }
            })
        }

        return inp;
    }

    $submitName() {
        return null
    }

    $onSubmitFile(data) {

    }


    $onSubmit(data) {
        this.setState({ loading: true });
        if (!data.limite_compra) {
            data.limite_compra = 0;
        } else {
            data.limite_compra = parseInt(data.limite_compra);
        }

        if (data.precio <= 0) {
            SPopup.alert("El producto no puede tener un precio menor o igual a 0")
            return;
        }

        if (!!this.state?.categoria_producto?.key) {
            data.key_categoria_producto = this.state.categoria_producto.key;
        };

        if (data.ley_seca == "true" && data.mayor_edad == "false") {
            SPopup.alert("No puede seleccionar ley seca si el producto no es para mayor de edad.");
            return;
        }

        if (this.$params.key_restaurante) {
            data.key_restaurante = this.$params.key_restaurante;
        } else {
            SPopup.alert("No hay Key Restaurante, reportar ese error.")
            return;
        }

        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: Model.usuario.Action.getKey(),
        }).then(async (resp) => {
            let files = this.form.getFiles();

            if (Array.isArray(files.image_profile)) {
                await Upload.sendPromise(files.image_profile[0], SSocket.api.root + "upload/producto/" + this.pk)
            }

            this.setState({ loading: false });
            SNavigation.navigate("/restaurante/profile/producto", { key_restaurante: this.$params.key_restaurante });
        }).catch(e => {
            this.setState({ loading: false });
            SPopup.alert(e.error)
            console.error(e);
        })
    }

    $footer() {
        return (
            <PButtom
                danger={true}
                fontSize={20}
                loading={this.state.loading}
                onPress={() => { this.form.submit() }}
            >
                Editar Producto
            </PButtom>
        );
    }
}

export default connect(index);