import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SNavigation, SPage, SPopup, SText, STheme, SView } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <SPage title={'Developer Tools'}>
                <SHr height={32} />
                <MenuPages path={"/devtool/"}  >
                    <MenuButtom label='Encrypt Cards' icon={<SIcon name='Card' />} url='/devtool/encryptar_tarjetas' />

                    <MenuButtom label='Tabla Historial' icon={<SIcon name='Ingreso' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/devtool/tabla_historial", evt);
                        });
                    }} />

                    {/* <MenuButtom label='Pedidos no recogidos' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/pedidos_no_recogidos", evt);
                        });
                    }} />
                    <MenuButtom label='Reportes Partner' icon={<SIcon name='Excel' />} onPress={() => {
                            SNavigation.navigate("/reporte/partner");
                    }} /> */}
                </MenuPages>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);