import { SDate, SHr, SIcon, SImage, SText, STheme, SView, SInput, SPopup, SLoad, SThread } from 'servisofts-component';
import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import Model from '../../Model';
import SSocket from 'servisofts-socket'
class index extends DPA.list {

    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "key_servicio", "estado"],
            menuType: "flex",
            onRefresh: (resolve) => {
                Parent.model.Action.CLEAR();
                this.state.data = null;
                this.__getData()
                resolve();
            }

        });
        this.state = {
            select: {
                "entregado": false,
                "pagado": false,
                "listo": true,
                "buscando_conductor": true,
                "confirmando_conductor": true,
                "esperando_conductor": true,
                "entregado_conductor": true,
                "conductor_llego": true,
            },

            filterFecha: {
                fecha_inicio: new SDate().toString("yyyy-MM-dd"),
                fecha_fin: new SDate().toString("yyyy-MM-dd"),
            },

            tipoPedido: {
                delivery: true,
                recoger: true,
            },
        }
    }

    __getData() {
        SSocket.sendPromise({
            component: "pedido",
            type: "getAll",
            fecha_inicio: this.state.filterFecha.fecha_inicio,
            fecha_fin: this.state.filterFecha.fecha_fin,
        }).then(e => {
            this.setState({ data: e.data })
        }).catch(e => {
            SPopup.alert(e.error)
        })
    }

    componentDidMount() {
        this.__getData();
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }

    $allowTable() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    }

    $getData() {
        // return Parent.model.Action.getAll({fecha_inicio: this.state.filterFecha.fecha_inicio, fecha_fin: this.state.filterFecha.fecha_fin});
        return this.state.data;
    }

    optionItem({ key, label, color }) {
        var select = !!this.state.select[key]
        return <SView height={35} center style={{
            paddingLeft: 8,
            paddingRight: 8,
            opacity: select ? 1 : 0.5,
            backgroundColor: color + "AA"
        }} onPress={() => {
            if (!select) {
                this.state.select[key] = true;
            } else {
                delete this.state.select[key];
            }
            this.setState({ ...this.state })
        }} row>
            {!select ? null : <> <SIcon name={"Close"} width={8} height={8} fill={STheme.color.text} /> <SView width={8} /></>}
            <SText>{label}</SText>
        </SView>
    }

    optionItemTipo({ key, label, color }) {
        var select = !!this.state.tipoPedido[key]
        return <SView height={35} center style={{
            paddingLeft: 8,
            paddingRight: 8,
            opacity: select ? 1 : 0.5,
            backgroundColor: color + "AA"
        }} onPress={() => {
            if (!select) {
                this.state.tipoPedido[key] = true;
            } else {
                delete this.state.tipoPedido[key];
            }
            this.setState({ ...this.state })
        }} row>
            {!select ? null : <> <SIcon name={"Close"} width={8} height={8} fill={STheme.color.text} /> <SView width={8} /></>}
            <SText>{label}</SText>
        </SView>
    }

    optionItemDate({ key }) {
        let date = this.state.filterFecha[key]
        return <>
            <SView col={"xs-12"} height={35} center style={{ padding: 5 }}>
                <SText style={{ fontSize: 10 }}>{key == "fecha_inicio" ? "Desde:" : "Hasta:"}</SText>
                <SInput type={"date"} value={date} height={20} width={100} center onChangeText={(val) => {

                    // if (new SDate(val, "yyyy-MM-dd").isAfter(new SDate())) {
                    //     SPopup.alert("No puedes seleccionar una fecha mayor a hoy.")
                    //     return;
                    // }

                    if (key == "fecha_inicio" && val > this.state.filterFecha.fecha_fin) {
                        SPopup.alert("No puedes seleccionar una fecha mayor a la fecha final.")
                        return;
                    }

                    this.state.filterFecha[key] = val;
                    this.setState({ ...this.state })
                    new SThread(2000, "delay", true).start(() => {
                        this.__getData();
                    })
                }} />
            </SView>
        </>

    }

    $menu() {
        let menu = super.$menu();
        menu.push({ children: this.optionItem({ key: "entregado", label: "Entregados", color: "#00FF00" }) })
        menu.push({ children: this.optionItem({ key: "pago_en_proceso", label: "Pago en proceso", color: "#FF6600" }) })
        menu.push({ children: this.optionItem({ key: "pagado", label: "Pagados", color: "#FF6600" }) })
        menu.push({ children: this.optionItem({ key: "listo", label: "Listos", color: "#FF6600" }) })
        menu.push({ children: this.optionItem({ key: "buscando_conductor", label: "Buscando conductor", color: "#FF6600" }) })
        menu.push({ children: this.optionItem({ key: "confirmando_conductor", label: "Confirmando conductor", color: "#FF6600" }) })
        menu.push({ children: this.optionItem({ key: "esperando_conductor", label: "Yendo a recoger", color: "#FF6600" }) })
        menu.push({ children: this.optionItem({ key: "entregado_conductor", label: "Entregado a Conductor", color: "#FF6600" }) })
        menu.push({ children: this.optionItem({ key: "conductor_llego", label: "Llego", color: "#FF6600" }) })
        menu.push({ children: this.optionItem({ key: "timeout_pago", label: "QR Vencido", color: "#FF0000" }) })
        menu.push({ children: this.optionItem({ key: "pendiente_pago", label: "Pendiente de Pago", color: "#FF0000" }) })
        menu.push({ children: this.optionItem({ key: "no_recogido", label: "No recogidos", color: "#FF0000" }) })
        menu.push({ children: this.optionItem({ key: "cancelado", label: "Cancelados", color: "#FF0000" }) })
        menu.push({ children: this.optionItem({ key: "anulado", label: "Anulado", color: "#FF0000" }) })
        // menu.push({ children: this.optionItem({ key: "pendiente_pago", label: "Pendientes de pago", color: "#FF6600" }) })

        menu.push({ children: this.optionItemTipo({ key: "delivery", label: "Delivery", color: "#FF00FF" }) })
        menu.push({ children: this.optionItemTipo({ key: "recoger", label: "Recoger de Lugar", color: "#FF00FF" }) })

        menu.push({ children: this.optionItemDate({ key: "fecha_inicio" }) })
        menu.push({ children: this.optionItemDate({ key: "fecha_fin" }) })
        return menu;
    }
    $allowAccess() {
        // return true;
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }

    $filter(data) {
        if (!this.state.select[data.state]) {
            return false;
        }

        if (data.delivery > 0 && !this.state.tipoPedido.delivery) {
            return false;
        }

        if (data.delivery == 0 && !this.state.tipoPedido.recoger) {
            return false;
        }

        if (data.fecha < this.state.filterFecha.fecha_inicio || data.fecha > this.state.filterFecha.fecha_fin) {
            return false;
        }

        return true;
    }

    $getData() {
        let usuarios = Model.usuario.Action.getAll();
        let pedidos = this.state.data
        let restaurantes = Model.restaurante.Action.getAll();
        let horario = Model.horario.Action.getAll();
        let pack = Model.pack.Action.getAll();
        if (!pedidos || !restaurantes || !horario || !pack) return null;
        return Object.values(pedidos).map(obj => {
            if (usuarios) {
                obj.usuario = usuarios[obj.key_usuario];
                obj.driver = usuarios[obj.key_conductor];
            }
            obj.pack = pack[obj.key_pack];
            obj.horario = horario[obj?.pack?.key_horario]
            obj.restaurante = restaurantes[obj?.horario?.key_restaurante]
            return obj
        })
    }

    $order() {
        return [{ key: "fecha", order: "desc" }]
    }

    $item(obj) {
        return <SView key={obj.key} col={"xs-12"} card padding={8} onPress={() => {
            this.$onSelect(obj)
        }}>
            <SView col={"xs-12"} row>
                <SView width={50} height={50} card>
                    <SImage src={Model.restaurante._get_image_download_path(SSocket.api, obj?.restaurante?.key)} />
                </SView>
                <SView width={4} />
                <SView flex row>
                    <SView flex>
                        <SText bold fontSize={14}>{obj?.restaurante?.nombre}</SText>
                        {/* <SHr /> */}
                        <SText color={STheme.color.lightGray} fontSize={10}>{obj?.fecha} {obj?.horario?.hora_inicio}  - {obj?.horario?.hora_fin}</SText>
                        <SText>{obj.precio} bs. x {obj.cantidad}</SText>
                        {obj.delivery > 0 ? <SText color={STheme.color.lightGray}>Delivery: {obj.delivery} Bs.</SText> : <SText color={STheme.color.lightGray}>Recoger del lugar</SText>}
                    </SView>
                    <SView width={120} height={24} style={{
                        borderRadius: 8,
                        backgroundColor: STheme.color.card,
                    }} center >
                        <SText fontSize={10} center col={"xs-5"}>{obj?.state}</SText>
                    </SView>
                </SView>
            </SView>

            <SHr />
            <SHr height={1} color='#666' />
            <SHr />

            {obj.usuario ?
                <SView col={"xs-12"} row>
                    <SView col={"xs-6"} row>
                        <SText>Cliente:</SText>
                        <>
                            <SHr h={4} />
                            <SView width={30} height={30} card>
                                <SImage src={Model.usuario._get_image_download_path(SSocket.api, obj.key_usuario)} />
                            </SView>
                            <SView width={4} />
                            <SView flex>
                                <SText bold fontSize={14}>{`${obj?.usuario?.Nombres} ${obj?.usuario?.Apellidos}`}</SText>
                                <SText bold fontSize={10}>Pedido hecho el:</SText>
                                <SText color={STheme.color.lightGray} fontSize={10}>{new SDate(obj?.fecha_on).toString("yyyy-MM-dd hh:mm")}</SText>
                            </SView>
                        </>

                    </SView>

                    <SView col={"xs-6"}>
                        <SText>Driver:</SText>
                        <SView row>
                            <SHr h={4} />
                            <SView width={30} height={30} card>
                                <SImage src={Model.usuario._get_image_download_path(SSocket.api, obj?.driver?.key)} />
                            </SView>
                            <SView width={4} />
                            <SView flex>
                                <SText bold fontSize={14}>{obj?.driver?.Nombres} {obj?.driver?.Apellidos}</SText>

                            </SView>
                        </SView>
                    </SView>
                </SView>
                : <SText center>No cargaron los usuarios, recargue la pagina.</SText>}
        </SView>
    }
}
export default connect(index);