import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SNavigation, SPage, SPopup } from 'servisofts-component';
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <SPage title={'Reportes Partner'}>
                <SHr height={32} />
                <MenuPages path={"/reporte/app"}  >
                    <MenuButtom label='REGISTROS POR ROL' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/app/registros_por_rol");
                    }} />

                    <MenuButtom label='CANTIDAD DE PEDIDOS' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/app/cantidad_pedidos", evt);
                        });
                    }} />


                    <MenuButtom label='DÍAS DE PEDIDOS' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/app/promedio_de_pedido_por_dia_de_la_semana", evt);
                        });
                    }} />

                    <MenuButtom label='LUGARES DE PEDIDOS' icon={<SIcon name='Excel' />} onPress={() => {
                        SNavigation.navigate("/reporte/app/lugares_pedidos");
                    }} />

                    <MenuButtom label='HORARIOS DE PEDIDOS' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/app/pedidos_por_horas_del_dia", evt);
                        });
                    }} />

                    <MenuButtom label='MÉTODOS DE PAGO' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/app/ranking_orden_pago", evt);
                        });
                    }} />
                    {/* <MenuButtom label='MEDALLAS ASFI' icon={<SIcon name='Excel' />} onPress={() => {
                        SPopup.dateBetween("Selecciona las fechas", (evt) => {
                            SNavigation.navigate("/reporte/app/medallas_asfi", evt);
                        });
                    }} /> */}
                </MenuPages>
                <SHr height={32} />

            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);