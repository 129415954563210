import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SButtom, SDate, SLoad, SMath, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'
import Model from '../../../Model'
class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {}
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })

        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/medallas_asfi.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin]
        }).then(e => {
            let keys = [...new Set(Object.values(e.data).map(a => a.key_usuario).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(e.data).map(a => {
                    a.usuario = e2?.data[a.key_usuario]?.usuario ?? {}
                })
                this.setState({ loading: false, error: "", data: e.data })
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }



    renderTable() {
        if (!this.state.data) return <SLoad />
        return <STable2
            header={
                [
                    // { key: "usuario-key", label: "cliente", width: 200, center: true, render: (val) => val.key },
                    { key: "usuario", label: "cliente", width: 200, center: true, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
                    { key: "cantidad_pedidos_con_tapeke", label: "Cantidad Pedidos", center: true, width: 100, order: "desc" },
                    { key: "medallas-cant-1", label: "Cantidad Comprometido", center: true, width: 50, render: a => a["c947681d-26a6-4d30-b125-ce52c033f0d7"]?.cantidad ?? 0 },
                    { key: "medallas-total-1", label: "Monto Comprometido", center: true, width: 100, render: a => SMath.formatMoney(a["c947681d-26a6-4d30-b125-ce52c033f0d7"]?.total_delivery ?? 0) },
                    { key: "medallas-cant-2", label: "Cantidad Amigo Verde", center: true, width: 50, render: a => a["cca5f9ae-023c-4ad8-a39b-638d451804f9"]?.cantidad ?? 0 },
                    { key: "medallas-total-2", label: "Monto Amigo Verde", center: true, width: 100, render: a => SMath.formatMoney(a["cca5f9ae-023c-4ad8-a39b-638d451804f9"]?.total_delivery ?? 0) },
                    { key: "medallas-cant-3", label: "Cantidad Guardian Sostenible", center: true, width: 50, render: a => a["442fda07-02b9-4f11-991f-48d8401dbda0"]?.cantidad ?? 0 },
                    { key: "medallas-total-3", label: "Monto Guardian Sostenible", center: true, width: 100, render: a => SMath.formatMoney(a["442fda07-02b9-4f11-991f-48d8401dbda0"]?.total_delivery ?? 0) },
                    { key: "medallas-total-cupon-3", label: "Monto Cupon Guardian Sostenible", center: true, width: 100, render: a => SMath.formatMoney(a["442fda07-02b9-4f11-991f-48d8401dbda0"]?.total_cupon ?? 0) },
                    { key: "medallas-cant-4", label: "Cantidad Heroe Tapeke", center: true, width: 50, render: a => a["0038d081-6575-4017-960f-c723ea166bc4"]?.cantidad ?? 0 },
                    { key: "medallas-total-4", label: "Monto Heroe Tapeke", center: true, width: 100, render: a => SMath.formatMoney(a["0038d081-6575-4017-960f-c723ea166bc4"]?.total_delivery ?? 0) },
                    { key: "medallas-total-cupon-4", label: "Monto Cupon Heroe Tapeke", center: true, width: 100, render: a => SMath.formatMoney(a["0038d081-6575-4017-960f-c723ea166bc4"]?.total_cupon ?? 0) },
                    { key: "logros", label: "Logros", center: true, width: 100 },
                    { key: "total_logros", label: "Monto Logros", center: true, width: 100, render: a => !a ? 0 : SMath.formatMoney(a ?? 0) },
                    // { key: "cantidad_tapeke", label: "Cantidad Tapeke", center: true, width: 100 },
                    // { key: "cantidad_producto", label: "Cantidad Producto", center: true, width: 100 },
                    // { key: "promedio_tapeke", label: "Promedio monto Tapeke", center: true, width: 120, render: val => `${parseFloat((val).toFixed(2))} Bs` },
                    // { key: "promedio_producto", label: "Promedio monto Producto", center: true, width: 120, render: val => `${parseFloat((val).toFixed(2))} Bs` },
                    // { key: "racha_pedidos", label: "Racha de Pedidos", center: true, width: 100 },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de insignias ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);