import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SButtom, SDate, SLoad, SMath, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'
// import Model from '../../../Model'
class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {}
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })

        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/historial/get_tabla_historial.psql",
            params: [this.params.fecha_inicio, this.params.fecha_fin]
        }).then(e => {
            let keys = [...new Set(Object.values(e.data).map(a => a.key_usuario).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(e.data).map(a => {
                    a.usuario = e2?.data[a.key_usuario]?.usuario ?? {}
                })
                this.setState({ loading: false, error: "", data: e.data })
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    renderTable() {
        if (!this.state.data) return <SLoad />
        return <STable2
            header={
                [
                    { key: "usuario", label: "Usuario Editor", width: 200, center: true, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
                    { key: "fecha_on", label: "Fecha Edición", width: 150, center: true },
                    { key: "tabla_name", label: "Modelo Editado", width: 150, center: true },
                    { key: "tabla_key", label: "key referencia registro", width: 250, center: true },
                    { key: "data", label: "Data Editada", width: 1000 },
                ]
            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de historial ediciones desde ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);